<style scoped>
.modalItem {
  height: 80vh;
  overflow: scroll;
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  text-align: center;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
}
.chooseDeptFormItem {
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 41px;
  line-height: 41px;
}
.chooseDeptFormItem .chooseDeptFormItemTitle {
  font-weight: bold;
  color: #2d4761;
  flex: 1;
}
.chooseDeptFormItem .detail {
  flex: 1;
}
</style>
<template>
  <div v-if="showModal">
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <Modal
      v-model="showChooseDept"
      fullscreen
      @onCancel="cancel"
      @onOk="chooseDept"
      :closable="false"
    >
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>选择{{ dataFrom }}</span>
      </p>
      <div v-show="isShowTime">
        <div class="chooseDeptFormItem">
          <div class="chooseDeptFormItemTitle">开始日期</div>
          <div class="detail">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              :clearable="false"
              :option="option"
              :value="pickerValue"
              @on-change="setStartDateValue"
              placeholder="请选择时间"
              width="200px"
            ></DatePicker>
          </div>
        </div>

        <div class="chooseDeptFormItem">
          <div class="chooseDeptFormItemTitle">结束日期</div>
          <div class="detail">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              :clearable="false"
              :option="option"
              :value="pickerEndValue"
              @on-change="setEndDateValue"
              placeholder="请选择时间"
              width="200px"
            ></DatePicker>
          </div>
        </div>
      </div>

      <div v-if="showSuper && isShowDept" class="modalItem" style="">
        <div style="margin: 20px 0">
          <RadioGroup v-model="dataFrom" type="button" button-style="solid">
            <Radio label="单位"></Radio>
            <Radio v-if="showChejian" label="车间"></Radio>
          </RadioGroup>
        </div>

        <div v-if="dataFrom == '单位'">
          <div class="detail">
            <CheckboxGroup
              v-model="deptIdArr"
              style="text-align: left; line-height: 30px"
            >
              <Checkbox
                v-for="item in deptList"
                :key="item.deptId"
                :label="item.deptId"
              >
                <span>{{ item.deptName }}</span>
              </Checkbox>
            </CheckboxGroup>
            <div style="margin-top: 5px">
              <i style="color: red; font-size: 10px"
                >不选择部门，默认查询全部</i
              >
            </div>
          </div>
        </div>
        <div v-if="dataFrom == '车间'">
          <div>
            <div
              style="padding: 8px 10px"
              class="commonShadow"
              v-for="(item, key) in deptList"
              :key="key"
            >
              <div
                style="
                  display: flex;
                  padding: 8px 10px;
                  justify-content: space-between;
                "
              >
                <div style="flex: 1; text-align: left">
                  <span>{{ item.deptName }}</span>
                </div>
                <div style="">
                  <Button type="default" @click="getChildDept(item)"
                    >展开</Button
                  >
                </div>
              </div>

              <div v-if="item.deptName == curDept.deptName">
                <CheckboxGroup
                  v-model="deptIdArr"
                  style="text-align: left; line-height: 35px"
                >
                  <Checkbox
                    v-for="item in childDept"
                    :key="item.deptId"
                    style="text-align: left; line-height: 35px"
                    :label="item.deptId"
                  >
                    <span>{{ item.deptName }}</span>
                  </Checkbox>
                </CheckboxGroup>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="chooseDept">确定</Button>
        <Button type="default" size="large" @click="cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";

import diaLogliu from "./dialogLiu.vue";
import { wxConPost, getUserPm } from "../api/httpApi";
import moment from "moment";

let curDate = new Date();

export default {
  name: "deptChooseCom",
  display: "选择单位",
  data () {
    return {
      showSuper: false, // 超管显示部门
      dataFrom: "单位",
      deptList: [], // 超过可选部门

      deptIdArr: [], // 选择部门Id

      childDept: [], // 全部子部门
      curDept: {},
      chooseZd: true,
      showChooseDept: false,
      // 提示框
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
      // 选择时间
      pickerValue: new Date(curDate.getTime() - 7 * 24 * 60 * 60 * 1000),
      pickerEndValue: curDate,
      startDate: "",
      endDate: "",

      option: {},
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    // 是否需要选择时间
    isShowTime: {
      type: Boolean,
      default: false,
    },
    isShowDept: {
      type: Boolean,
      default: true,
    },
    defaultStartDate: {
      type: String,
      default: "",
    },
    defaultEndDate: {
      type: String,
      default: "",
    },
    // 是否显示车间
    showChejian: {
      type: Boolean,
      default: true,
    },
  },
  watch: {},
  computed: {},
  components: { diaLogliu },
  methods: {
    async getDept () {
      try {
        const result = await wxConPost(
          "/services/gttt-stats-mgt/dept/getDept",
          {
            level: 2,
          }
        );
        // this.de;
        this.deptList = result.ret;
        // 自定义排序函数
        const sortByFirstChar = (a, b) => {
          // 提取首汉字并进行比较
          return a.deptName.charAt(0).localeCompare(b.deptName.charAt(0), 'zh-CN', { sensitivity: 'base' });
        };

        // 使用sort方法进行排序
        this.deptList.sort(sortByFirstChar);
      } catch (e) { }
    },
    async getChildDept (item) {
      this.deptIdArr = [];
      this.childDept = [];
      this.curDept = item;
      try {
        const result = await wxConPost(
          "/services/gttt-stats-mgt/dept/getDept",
          {
            level: 2,
            parentid: item.deptId,
          }
        );
        // this.de;
        this.childDept = result.ret;
        // 自定义排序函数
        const sortByFirstChar = (a, b) => {
          // 提取首汉字并进行比较
          return a.deptName.charAt(0).localeCompare(b.deptName.charAt(0), 'zh-CN', { sensitivity: 'base' });
        };

        // 使用sort方法进行排序
        this.childDept.sort(sortByFirstChar);
        this.chooseZd = false;
      } catch (e) { }
    },
    chooseDept () {
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }

      let emitData = {
        type: this.dataFrom,
        deptIdArr: this.deptIdArr,
      };
      if (this.isShowTime) {
        emitData["startDate"] = this.startDate;
        emitData["endDate"] = this.endDate;
      }
      this.$emit("chooseDept", emitData);
    },
    cancel () {
      this.$emit("cancel");
    },

    /****************************** 设置时间 */
    setEndDateValue (date) {
      this.endDate = date;
      this.pickerEndValue = new Date(date);
      // console.log("enddate-----", date, this.startDate, this.endDate);
      if (this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
    },
    setStartDateValue (date) {
      this.startDate = date;
      this.pickerValue = new Date(date);
      // console.log("startdate-----", date, this.startDate, this.endDate);
      if (this.endDate && this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
    },
  },

  async created () {
    // 若需日期
    if (this.isShowTime) {
      // 初始化开始日期
      if (this.defaultStartDate) {
        this.startDate = moment(this.defaultStartDate).format("YYYY-MM-DD");
        this.pickerValue = new Date(this.startDate);
      } else {
        this.startDate = moment(new Date(this.pickerValue)).format(
          "YYYY-MM-DD"
        );
      }

      // 初始化结束日期
      if (this.defaultEndDate) {
        this.endDate = moment(this.defaultEndDate).format("YYYY-MM-DD");
        this.pickerEndValue = new Date(this.endDate);
      } else {
        this.endDate = moment(new Date(this.pickerEndValue)).format(
          "YYYY-MM-DD"
        );
      }
    }
    // 验证查询权限
    const userPm = getUserPm();
    if (userPm && userPm.pmSuper) {
      this.showSuper = true;
      this.getDept();
    }
  },
  watch: {
    showModal: function (val) {
      this.showChooseDept = this.showModal;
    },
    dataFrom: function (val) {
      // 清空部门
      this.deptIdArr = [];
    },
  },
  beforeDestroy () {
    this.showChooseDept = false;
  },
};
</script>
